@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");

:root {
  --box-shadow: 0 1px 3px rgba(204, 204, 204, 0.12),
    0 1px 2px rgba(204, 204, 204, 0.24);
}
#root {
  height: 100vh;
  width: 90%;
  max-width: 900px;
}
* {
  box-sizing: border-box;
}

::placeholder {
  color: whitesmoke;
  opacity: 0.5;
}

body {
  background-color: #444;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  margin: 0;
  font-family: "Lato", sans-serif;
  color: white;
}
.vertically-centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.container {
  margin: 10px auto;
  width: 100%;
}

h1 {
  letter-spacing: 1px;
  margin: 0;
}

h3 {
  border-bottom: 1px solid #bbb;
  padding-bottom: 10px;
  margin: 40px 0 10px;
}

h4 {
  margin: 0;
  text-transform: uppercase;
}

.inc-exp-container {
  background-color: #333;
  box-shadow: var(--box-shadow);
  padding: 20px;
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.inc-exp-container > div {
  flex: 1;
  text-align: center;
}

.inc-exp-container > div:first-of-type {
  border-right: 1px solid #dedede;
}

.accounts-container {
  width: 100%;
  background-color: #333;
  box-shadow: var(--box-shadow);
  padding: 15px;
  margin: 20px 0;
}

.accounts-container h2 {
  margin: 0;
  text-transform: none;
}

.accounts {
  background-color: #262727;
  box-shadow: var(--box-shadow);
  margin: 5px;
  padding: 5px;
  display: flex;
}

#account {
  display: flex;
  flex-wrap: nowrap;
  width: 90%;
}
#account-name {
  display: inline-block;
  width: 50%;
  overflow-wrap: break-word;
  color: white;
  text-decoration: none;
  text-align: left;
}
#account-name:hover {
  text-decoration: underline;
  text-decoration-color: #3c94e7;
}

#account-value {
  text-align: right;
  margin-left: auto;
  display: inline-block;
  width: 50%;
  overflow-wrap: break-word;
}

#delete-account {
  margin: 0% 1%;
  text-align: right;
  width: 8%;
}

.del-acc-btn {
  cursor: pointer;
  background-color: #444;
  color: white;
  border: 1px solid transparent;
}

.del-acc-btn:hover {
  margin: 0;
  border: 1px solid #444;
  background: #e74c3c;
}

.negative-balance {
  color: white;
}

.positive-balance {
  color: #2ecc71;
}

.addAccount {
  cursor: pointer;
  background-color: #444;
  color: white;
  font-size: 1.3em;
  float: right;
  border-radius: 50%;
}

.money {
  font-size: 16px;
  letter-spacing: 1px;
}

.money.plus {
  text-align: right;
  width: 45%;
  color: #2ecc71;
}

.money.revenue {
  color: #2ecc71;
  font-size: 20px;
  text-align: center;
}
.money.expenses {
  color: #c0392b;
  font-size: 20px;
  text-align: center;
}

.money.minus {
  text-align: right;
  width: 45%;
  color: #c0392b;
}

label {
  display: inline-block;
  margin: 10px 0;
}

input[type="text"],
input[type="number"] {
  display: block;
  font-size: 16px;
  width: 100%;
}

button:focus {
  outline: 0;
}

.list {
  max-height: 300px;
  overflow: hidden;
  overflow-y: auto;
  list-style-type: none;
  padding: 0;
}

.list li {
  background-color: #262727;
  box-shadow: var(--box-shadow);
  color: white;
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 10px;
  margin: 10px 0;
}

.list li.plus {
  padding: 5px;
  border-bottom: 2px solid #2ecc71;
}

.list li.minus {
  padding: 5px;
  border-bottom: 2px solid #c0392b;
}

#transaction-name {
  width: 45%;
}
.delete-btn {
  cursor: pointer;
  background-color: #e74c3c;
  border: 1px solid #e74c3c;
  color: white;
  padding: 3px;
  margin-left: 3px;
}

.delete-btn:hover {
  border: 1px solid #ccc;
  background: #e74c3c;
}

.list li:hover .delete-btn {
  opacity: 1;
}

.glow-on-hover {
  padding: 1em 3em;
  font-size: 1.3rem;
  border: none;
  outline: none;
  color: #fff;
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 2em;
  margin-left: auto;
  margin-right: auto;
}

.glow-on-hover:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-radius: 2em;
}

.glow-on-hover:active {
  color: white;
}

.glow-on-hover:active:after {
  background: transparent;
}

.glow-on-hover:hover:before {
  opacity: 1;
}

.glow-on-hover:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #111;
  left: 0;
  top: 0;
  border-radius: 2em;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.login-buttons {
  text-align: center;
  margin: 1.5em;
}
.login-buttons:last-child {
  margin-bottom: 8rem;
}
.login-buttons {
  text-align: center;
  margin: 1.5em;
}
#login-text {
  text-align: center;
  color: #2c3e50;
  white-space: nowrap;
  margin-bottom: 2rem;
  font-size: 4em;
  font-family: sans-serif;
  letter-spacing: 0.1em;
  transition: 0.3s;
  text-shadow: 1px 1px 0 grey, 1px 2px 0 grey, 1px 3px 0 grey, 1px 4px 0 grey,
    1px 5px 0 grey, 1px 6px 0 grey, 1px 7px 0 grey, 1px 8px 0 grey,
    5px 13px 15px black;
}

#login-text:hover {
  transition: 0.3s;
  transform: scale(1.2);
  text-shadow: 1px -1px 0 grey, 1px -2px 0 grey, 1px -3px 0 grey,
    1px -4px 0 grey, 1px -5px 0 grey, 1px -6px 0 grey, 1px -7px 0 grey,
    1px -8px 0 grey, 5px -13px 15px black, 5px -13px 25px #808080;
}

.pill-btn {
  cursor: pointer;
  display: inline-block;
  width: 100px;
  border: 0;
  background: none;
  text-align: center;
  border: 2px solid #67809f;
  padding: 13px;
  outline: none;
  color: white;
  border-radius: 24px;
  transition: 0.25s;
}

.pill-btn:hover {
  background: #67809f;
}

#home-text {
  width: 50%;
  margin: 0;
  text-align: left;
  display: inline-block;
}
#home-button {
  width: 50%;
  text-align: right;
  display: inline-block;
}

#logo {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.account-balance {
  text-align: center;
  margin: 10px;
}

#transaction-container > h3 {
  margin-top: 5px;
}

.form-box {
  background-color: #444444; /*temporary*/
  color: white;
}

.form-label {
  width: 100%;
  text-align: center;
}

.form-box input[type="text"],
.form-box input[type="number"] {
  width: 80%;
  max-width: 400px;
  border: 2px solid #446cb3;
  background: none;
  margin: 5px auto;
  text-align: center;
  padding: 14px 10px;
  outline: none;
  color: white;
  border-radius: 5px;
  transition: 0.25s;
}

.form-box input[type="text"]:focus,
.form-box input[type="number"]:focus {
  max-width: 500px;
  width: 100%;
  border: 2px solid white;
}

.form-submit {
  cursor: pointer;
  width: 50%;
  max-width: 270px;
  border: 2px solid #446cb3;
  background: #446cb3;
  color: white;
  padding: 10px 10px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  text-align: center;
  outline: none;
}
.form-submit:hover {
  background-color: gray;
}

#account-list > div {
  display: inline-block;
  width: 50%;
}
#account-list > div > button {
  cursor: pointer;
  background: none;
  border: 2px solid #f64747;
  color: white;
  text-align: center;
  margin-left: 85%;
  width: 15%;
  outline: none;
}
#account-list > div > button:hover {
  background: #f64747;
  border: 2px solid #f64747;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.527);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.527);
}
::-webkit-scrollbar-thumb {
  height: 1em;
  background-color: #ccc;
}

select::-webkit-scrollbar-thumb {
  height: 1em;
  background-color: #333;
}

#color-picker {
  padding: 10px 0px;
  margin: auto;
  width: 252px;
}

.budgets-container {
  width: 100%;
  background-color: #333;
  box-shadow: var(--box-shadow);
  padding: 15px 10px;
  margin: 20px 0;
}

.budgets-container h2 {
  margin: 0;
  text-transform: none;
}

.budgets {
  background-color: #262727;
  box-shadow: var(--box-shadow);
  margin: 5px 0;
  padding: 5px;
  display: flex;
}

#budget {
  display: inline-block;
  width: 90%;
}

#budget-name {
  display: inline-block;
  width: 50%;
  overflow-wrap: break-word;
  color: white;
  text-decoration: none;
  text-align: left;
  margin-bottom: 5px;
}

#budget-amount {
  text-align: right;
  margin-left: auto;
  display: inline-block;
  width: 50%;
  overflow-wrap: break-word;
}

#delete-account {
  margin: 0% 1%;
  text-align: right;
  width: 8%;
}

.edit-acc-btn {
  display: block;
  cursor: pointer;
  background-color: #444;
  color: white;
  border: 1px solid transparent;
  margin: 2px 0 0 auto;
}

.edit-acc-btn:hover {
  border: 1px solid #444;
  background: #2c82c9;
}

#budget-progress {
  position: relative;
  width: 100%;
  display: block;
  border-radius: 0 10px 10px 0;
}

#budget-percentage {
  color: black;
  font-weight: bold;
  text-align: center;
  display: block;
}

#budget-percentage > span {
  text-align: center;
}

#progress-bar {
  width: 100%;
  background: #ccc;
  position: relative;
}

.dropbtn {
  border: none;
  margin: 0 3px 0 0;
  background: #446cb3;
}

.dropdown:hover .dropbtn {
  background-color: #444;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content span {
  float: none;
  color: black;
  padding: 0 5px;
  text-decoration: none;
  display: inline-block;
  text-align: left;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

#selectBudgets {
  outline: none;
  display: block;
  background: #b1b1b1;
  margin-left: auto;
  margin-right: auto;
  width: 250px;
  overflow-wrap: break-word;
  overflow-y: auto;
}
#selectBudgets option {
  border-bottom: 1px solid #446cb3;
}

.sort {
  display: flex;
  justify-content: space-between;
}
